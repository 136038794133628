import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`In Demand Products`}</h2>
    <ProjectCard title="Real Property Tax Online Payment" link="https://www.erpt-marilao.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Real Property Tax - Assessment and Appraisal" link="https://www.bulacan.gov.ph/government/rptis.php" bg="linear-gradient(to right, #797909 0%, #09791c 100%)" mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Real Property Tax - Billing and Collection" link="https://www.bulacan.gov.ph/government/rptis.php" bg="linear-gradient(to right, #350979 0%, #093979 100%)" mdxType="ProjectCard"></ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      