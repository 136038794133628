import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Experience Summary`}</h2>
    <p>{`We are confident that our team of highly experienced and competent IT specialists will be able
to provide you the best support services relative to the customization and actual implementation
of the software. We commit to provide you not just the software but also all the necessary
assistance to transfer the technology to make the system operate smoothly. Our 27 years of actual
and rigid experience to date on IT services in the fields of:`}</p>
    <ul>
      <li parentName="ul">{`Accounting`}</li>
      <li parentName="ul">{`Purchasing`}</li>
      <li parentName="ul">{`Materials Planning`}</li>
      <li parentName="ul">{`Human Resource`}</li>
      <li parentName="ul">{`Time Keeping`}</li>
      <li parentName="ul">{`Payroll`}</li>
      <li parentName="ul">{`Sales & Inventory`}</li>
      <li parentName="ul">{`Billing & Collection`}</li>
      <li parentName="ul">{`Bussiness Permit & Licensing`}</li>
      <li parentName="ul">{`Property Assessment & Appraisal`}</li>
    </ul>
    <h2>{`Consultancy Experience:`}</h2>
    <ul>
      <li parentName="ul">{`(ARD/GOLD) Associate in Rural Development/Governance on Local Democracy Programs
funded by (USAID) United States Agency for International Development;`}</li>
      <li parentName="ul">{`World Bank Australian Agency for International Development (AUSAID) here in the Philippines;`}</li>
      <li parentName="ul">{`Philippine Postal Corporation;`}</li>
      <li parentName="ul">{`Philippine Coconut Authority.`}</li>
      <li parentName="ul">{`Hytec Power Incorporation`}</li>
    </ul>
    <p>{`Was one of the representatives of Liberal Party (LP) as a source code reviewer written by Smartmatic (2013 Senatorial Election).
Has a comprehensive experience on other related fields.`}</p>
    <h2>{`Other Products`}</h2>
    <ul>
      <li parentName="ul">{`Business Permit and Licensing System`}</li>
      <li parentName="ul">{`Business Tax Online Payment`}</li>
      <li parentName="ul">{`Tricycle Operators Permit Information System`}</li>
      <li parentName="ul">{`Personnel and Payroll Management Information System`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      